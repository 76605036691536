<template>
    <div v-if="dataCom" class="contain">
        <!-- <div class="temp-num">{{ userInfo.vote_num }}</div> -->
        <section class="top-group">
            <div class="order">{{ detailInfo.id }}</div>
            <div class="info">
                <div class="name">{{ detailInfo.nickname }}</div>
                <div class="num">
                    当前 <span>{{ detailInfo.vote_num }}票</span>
                </div>
            </div>
        </section>
        <div class="title">
            {{ detailInfo.title }}
        </div>
        <div class="video-group">
            <video
                class="my-video"
                id="my-video"
                controls
                playsinline="true"
                webkit-playsinline="true"
                :poster="detailInfo.video_image"
                :src="detailInfo.video"
            ></video>
        </div>
        <section class="btn-group">
           
            <div class="item " @click="submitPiao">
                <span>投票</span>
                
            </div>
            <div class="item abs" @click="openShare">
                <span>分享</span>
                <img class="tip" src="./img/tips.png" alt="" />
            </div>
        </section>
        <shareModal :visible.sync="shareModalVisible" />
        <projectModal :dataObj="gyObj" :visible.sync="projModalVisible" @closeModal="closeModalProj"/>
        <qrModal :visible.sync="qrModalVisible" @closeModal="closeModalQr"></qrModal>
        <div style="height:80px"></div>
    </div>
</template>

<script>
import shareModal from '../common/shareModal';
import projectModal from '../common/projectModal';
import qrModal from '../common/qrModal/index.vue';
import { authChecker, shareCtrl, tracking, utils } from '@/common/js/mmcModule';
import api from './api';

let reqObj = utils.getRequestParams();

const pageKey = 'h5_pop_jsb_lovesound_detail';
import saUtils from 'sensors-data-utils';

export default {
    data() {
        return {
            shareModalVisible: false,
            projModalVisible: false,
            qrModalVisible: false,
            detailID: reqObj.id,
            userInfo: {},
            gongYiList: [],
            detailInfo: {},
            dataCom: false,
        };
    },
    components: {
        shareModal,
        projectModal,
        qrModal,
    },
    computed: {
        gyObj() {
            let n = Math.floor(Math.random() * this.gongYiList.length);
            this.gongYiList[n].useruuid = this.userInfo.uuid;
            return this.gongYiList[n];
        },
    },
    methods: {
        openShare() {
            this.shareModalVisible = true;
            saUtils.click('button_share')
        },
        closeModalQr(){
            saUtils.click('button_close_followwx','h5_pop_jsb_lovesound_detail_followwx')
            
        },
        closeModalProj(){
            saUtils.click('button_close_zerotickets','h5_pop_jsb_lovesound_detail_zerotickets')
        },
        submitPiao() {
            
            if (this.userInfo.activity_state == 1) {
                if (this.userInfo.subscribe == 0) {
                    this.qrModalVisible = true;
                    saUtils.popup('h5_pop_jsb_followwx')
                } else {
                    if (this.userInfo.vote_num == 0) {
                        if(!this.userInfo.is_donate){
                            this.projModalVisible = true;
                            saUtils.popup('h5_pop_jsb_zerotickets')
                        }else{
                            alertMsg('当前余0票，您可分享获得加票');
                        }
                        
                    } else {
                        showLoading('提交中');
                        // console.log(san, 333);
                        api.postPiao({
                            story_id: Number(this.detailID),
                            share_uuid: reqObj.share_uuid,
                        }).then(res => {
                            hideLoading();
                            if (res.code == 0) {
                                this.userInfo.vote_num -= 1;
                                this.detailInfo.vote_num += 1;

                                if (this.userInfo.vote_num == 0) {
                                    alertMsg(
                                        '投票成功，转发好友投票成功得加票'
                                    );
                                } else {
                                    alertMsg(
                                        `投票成功，今日还可投${this.userInfo.vote_num}票`
                                    );
                                }
                            }
                        });
                    }
                }
            } else {
                alertMsg('活动已结束');
            }
            saUtils.click('button_vote')
        },
    },
    created() {
        let prUserInfo = api.getUserInfo();
        let prDetail = api.getDetail(this.detailID);
        let prGongYiList = api.getGongYiList();

        authChecker.check({ notneedphone: 1 }, res => {
            showLoading('加载中...');
            Promise.all([prUserInfo, prDetail, prGongYiList]).then(resArr => {
                this.dataCom = true;
                hideLoading();

                this.userInfo = resArr[0].data;
                this.detailInfo = resArr[1].data;

                this.gongYiList = resArr[2].data;
                shareCtrl.init({
                    title: this.detailInfo.title,
                    imgUrl: `https://m.jisuchou.net/fund/love-sound-share.png`,
                    desc: '我在参加为流浪动物发声活动，请帮我投票发声',
                    url: `https://m.jisuchou.net/fund/activity/lovesound/detail?id=${this.detailID}&share_uuid=${this.userInfo.uuid}`,
                    titleMoment: this.detailInfo.title,
                    shareRecord: {
                        scene: 'h5_jsb_lovesound_detail',
                    },
                });

                tracking.init();
                saUtils.init(pageKey);
                saUtils.pv();
            });
        });
    },
};
</script>

<style lang="less" scoped>
.contain {
    width: 100%;
    min-height: 100vh;
    background-image: url(./img/detail-bg.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding: 0 23px;
    position: relative;
    background-color: #f8ffee;

    .temp-num {
        background-color: #fff;
        position: absolute;
        width: 100px;
        height: 100px;
        left: 0;
        top: 0;
        color: #000;
        z-index: 12;
    }

    .top-group {
        display: flex;
        align-items: flex-start;
        justify-content: left;

        .order {
            height: 75px;
            width: 48px;
            background-color: #6dc539;
            font-size: 23px;
            color: #fff;
            font-weight: 500;
            line-height: 65px;
            border-radius: 0 0 24px 24px;
        }
        .info {
            margin-left: 15px;
            margin-top: 18px;
            text-align: left;
            .name {
                font-size: 21px;
                line-height: 1.4em;
                color: #333;
                font-weight: 500;
            }
            .num {
                margin-top: 10px;
                font-size: 16px;
                line-height: 1.4em;
                color: #666;
                span {
                    color: #ff874b;
                }
            }
        }
    }
    .title {
        margin-top: 15px;
        color: #333;
        font-size: 16px;
        line-height: 1.4em;
        font-weight: 500;
        text-align: left;
    }
    .video-group {
        // text-align: left;
        margin-top: 15px;
        .my-video {
            width: 100%;
            // height: 438px;
            border-radius: 8px;
        }
    }
    .btn-group {
        // width: 329px;
        margin: 0 0px;
        margin-top: 58px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item {
            width: 48%;
            background-image: linear-gradient(180deg, #ffce6a, #ff6217);
            border-radius: 25px;
            color: #fff;
            font-size: 22px;
            line-height: 22px;
            padding: 11px;
            font-weight: 500;
        }
        .abs {
            position: relative;
            .tip {
                width: 129px;
                height: 28px;
                position: absolute;
                right: 0;
                top: -28px;
            }
        }
    }
}
</style>
