<template>
    <div v-if="visible" class="modal-wrap">
        <div class="tip">
            <img
                class="btn-close"
                src="./img/btn-close.png"
                @click="closeModal"
                alt=""
            />
            <div class="info">
                <img class="pic" :src="dataObj.project_thumb" alt="">
                <div class="title">{{dataObj.title}}</div>
                <section class="num-group">
                    <div>已筹：<span class="yellow">{{dataObj.accumulative_number/100}}元</span></div>
                    <div>目标：<span class="yellow">{{dataObj.raisefunds_number/100}}元</span></div>
                </section>
            </div>
            <div class="item" @click="toHelp">
                帮帮ta
                <img class="tip-five" src="./img/tip-five.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import groupUnifiedTracking from '@/common/js/groupUnifiedTracking';
export default {
    props: ['visible','dataObj'],
    methods: {
        closeModal() {
            this.$emit('closeModal');
            this.$emit('update:visible', false);
        },
        toHelp() {
            
            let tzUrl  = groupUnifiedTracking.updateUrl(`https://yglian.qschou.com/gongyi/publicSite/detail?ChannelId=${this.dataObj.channel}&id=${this.dataObj.uuid}&mp=petuser.${this.dataObj.useruuid}`,'button.p.20231106.activeindex.zeroticketshelp')
            // console.log(tzUrl)
            location.href = tzUrl;
        },
    },
};
</script>

<style lang="less" scoped>
.modal-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    .tip {
        width: 325px;
        height: 534px;
        background-image: url(./img/proj-modal.png);
        background-size: 100% auto;
        background-repeat: no-repeat;
        position: relative;
        .btn-close {
            position: absolute;
            top: -40px;
            right: 0;

            width: 28px;
            height: 28px;
        }
    }
    .item {
        
        margin: 34px 15px 0;
        background-image: linear-gradient(180deg, #ffce6a, #ff6217);
        border-radius: 25px;
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        line-height: 20px;
        padding: 10px;
        position: relative;
        .tip-five {
            position: absolute;
            top: -28px;
            right: 0px;
            width: 128px;
            height: 28px;
        }
    }
    .info{
        margin:82px 33px 0;
        text-align: left;
        
        
        .pic{
            width: 258px;
            height: 258px;
            border-radius: 20px;
        }
        .title{
            font-size: 15px;
            line-height: 1.4em;
            color: #333;
            font-weight: 500;
            margin-top: 12px;
        }
        .num-group{
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            font-size: 13px;
            line-height: 1.4em;
            color: #999;
            font-weight: 500;
            .yellow{
                color: #FF7B39;
            };
        }
    }
}
</style>
