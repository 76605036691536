import { mmcAjax } from '@/common/js/mmcModule';

function getStoryList() {
    return mmcAjax({
        method: 'get',
        url: `${window.APIBasePath}mmc/story/list`,
        crossDomain: true,
    })
}

function getUserInfo() {
    return mmcAjax({
        method: 'get',
        url: `${window.APIBasePath}mmc/story/user`,
        crossDomain: true,
    })
}


function getGongYiList() {
    return mmcAjax({
        method: 'get',
        url: `${window.APIBasePath}mmc/story/project/list`,
        crossDomain: true,
    })
}

function getCongWuList() {
    const time = Math.round(new Date() / 1000);
    return mmcAjax({
        method: 'get',
        url: `${window.APIBasePath}mmc/project/show/list/1?size=100&time=${time}`,
        crossDomain: true,
    })
}

function getDetail(n) {
    return mmcAjax({
        method: 'get',
        url: `${window.APIBasePath}mmc/story/detail/${n}`,
        crossDomain: true,
    })
}


function postPiao(data) {

    return mmcAjax({
        method: 'post',
        url: `${window.APIBasePath}mmc/story/vote`,
        data,
        crossDomain: true,
    })
}

export default {
    getStoryList,
    getUserInfo,
    getGongYiList,
    getCongWuList,
    getDetail,
    postPiao,
}