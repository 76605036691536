<template>
  <div v-if="visible" class="modal-wrap" @click="closeModal" >
    <div class="tip">
        <img src="./share-tip.png" alt="">
        
    </div>
  </div>
</template>

<script>
export default {
    props:['visible'],
    methods:{
        closeModal(){
            this.$emit('closeModal')
            this.$emit('update:visible',false)
        }
    
    }
}
</script>

<style lang="less" scoped>
.modal-wrap{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: right;
    .tip{
        margin-top: 10px;
        margin-right: 15px;
        width: 338px;
        height: 128px;
    }
}
</style>