<template>
    <div v-if="visible" class="modal-wrap">
        <div class="tip">
            <img class="pic" src="./qrcode-cw.png" alt="" />
            <img
                class="btn-close"
                src="./btn-close.png"
                @click="closeModal"
                alt=""
            />
        </div>
    </div>
</template>

<script>
export default {
    props: ['visible'],
    methods: {
        closeModal() {
            this.$emit('closeModal');
            this.$emit('update:visible', false);
        },
    },
};
</script>

<style lang="less" scoped>
.modal-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    .tip {
        .pic {
            width: 319px;
            height: 500px;
        }
        background-size: 100% auto;
        background-repeat: no-repeat;
        position: relative;
        .btn-close {
            position: absolute;
            top: -40px;
            right: 0;

            width: 28px;
            height: 28px;
        }
    }
}
</style>
